import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import ServiceHero from "../components/service-hero";
import Layout from "../components/layout";
import ReviewCard from "../components/review-card";

const Testimonials = () => {
	const [reviews, setReviews] = useState([]);

	useEffect(() => {
		axios
			.get(
				`https://www.local-marketing-reports.com/external/showcase-reviews/widgets/c223c788b26cd7c5b88a4dc08e96859695a68996`
			)
			.then((res) => {
				const reviewData = res.data.results;
				setReviews(reviewData);
				setReviews(reviewData);
				document.getElementById("loading-spinner").style.display = "none";
			});
	});

	const data = useStaticQuery(
		graphql`
			query {
				heroImg: file(relativePath: { eq: "kitchen.jpg" }) {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: FULL_WIDTH
							placeholder: BLURRED
						)
					}
				}
				site {
					siteMetadata {
						siteUrl
					}
				}
			}
		`
	);
	const schemaImg = data.heroImg.publicURL;
	const heroImg = data.heroImg.childImageSharp.gatsbyImageData;
	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Prime Sparks",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Testimonials",
				item:{ 
					url:`${siteUrl}/testimonials`,
					id:`${siteUrl}/testimonials`,
			},
		},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Our Testimonials for Electrical Services"
				description=""
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Our Testimonials for Electrical Services",
					description: "Our electrician testimonials",
					images: [
						{
							url: `${schemaImg}`,
							width: 1920,
							height: 1080,
							alt: "Prime Sparks Electrical Services",
						},
					],
				}}
			/>
			<ServiceHero
				heading="Our Customer Testimonials"
				text={
					<span>
						Are you looking for a reliable, local electrician to repair <br />{" "}
						or replace an electrical installation?
					</span>
				}
				isDarken={true}
				image={heroImg}
			/>
			<Container className="py-5">
				<Col>
					<Row
						className="justify-content-center align-items-center"
						style={{ minHeight: "50vh" }}
					>
						<Spinner id="loading-spinner" variant="primary" animation="border">
							<span className="sr-only">Loading...</span>
						</Spinner>
						<div className="card-columns">
							{reviews.map((review) => (
								<ReviewCard review={review} />
							))}
						</div>
					</Row>
				</Col>
			</Container>
		</Layout>
	);
};

export default Testimonials;
